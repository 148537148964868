import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import StatesJson from './states.json';
import TopcityJson from './topcities.json';
import { Router } from '@angular/router';
import {FindafloristapiService} from '../findafloristapi.service'
import { Florist } from '../classes/florist';


interface STATES {
  name: String;
  abbreviation: String;
}
interface TOPCITY {
  name: String;
}

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {
  title='Site Map';
  floristsinfo:Florist


  States: STATES[] = StatesJson;
  Topcity: TOPCITY[] = TopcityJson;
  

  constructor(private _findafloristapiservice:FindafloristapiService, private router: Router, private elementRef: ElementRef){
   
  }

  citylist(event){
    let routercitylist = event.srcElement.rel
    let routercityparms = event.srcElement.id
      sessionStorage.setItem('townvalue',routercitylist);
    this.router.navigateByUrl('/townslist/'+ routercitylist +'?state='+routercityparms);

  } 
  topcity(event){
   
    /*var oflorist = new Florist();
    oflorist.SearchText = event.srcElement.id;
    oflorist.LiveOnly= true;
    oflorist.SearchType="CSZ"
    this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ this.floristsinfo= data;});*/
    sessionStorage.setItem('floristval',event.srcElement.id);
    sessionStorage.setItem('srchtyp',"CSZ");
    let townroutelink =event.srcElement.id;
    this.router.navigateByUrl('/flowerdelivery/'+ townroutelink +'?type=CSZ&CSZ='+townroutelink);
  } 
 
  ngOnInit(): void {
  }
  hasRoute(route: string) {
    return this.router.url.includes(route);
  }
   

}
