<div class="row breadcrumbwrap">
    <div class="container">
        <div class="row">
            <div class="col-5">
                <ul>
                  <a href="/">Home</a>
                  <span class="brdcrumd"> > </span>
                  <a href="/sitemap" *ngIf="hasRoute('townslist')">Sitemap</a>
                  <a href="" *ngIf="hasRoute('florist')">{{floristrebreadcumresults | titlecase}}</a>
                  <span class="brdcrumd" *ngIf="hasRoute('townslist')"> > </span>
                  <a href="" *ngIf="hasRoute('townslist')">{{floristrebreadcumresults | titlecase}}</a>                  
                    <span *ngFor="let breadcrumb of (breadcrumbs$ | async)">
                      <a class="brdcrntpath">{{ breadcrumb.label }}</a>
                    </span>
                  </ul>
            </div>
            <div class="col-7" style="display: flex;" *ngIf="hasRoute('florist')">                  
                 <div class="col-4"> <label class="switch" >
                   <!--<button class="opensunday" *ngIf="hasRoute('florist')" (click)="opensunday()"></button>!-->
                    <input type="checkbox" (click)="opensunday()">
                    <span class="slider round"></span>
                  </label>
                  <span class="opensundaytxt">OPEN SUNDAY</span>
                </div>
                <div class="col-6">
                 <!-- <button class="smdd" *ngIf="hasRoute('florist')">SAME DAY DELIVERY</button>!-->
                  <label class="switch">
                    <input type="checkbox">
                    <span class="slider round"></span>
                  </label>
                  <span class="smddtxt">SAME DAY DELIVERY</span>
                </div>
                  <!--<div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle distance" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="hasRoute('search')">
                        DISTANCE
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Distance</a>
                      <a class="dropdown-item" href="#">2KM</a>
                      <a class="dropdown-item" href="#">5KM</a>
                      <a class="dropdown-item" href="#">10KM</a>
                      <a class="dropdown-item" href="#">15KM</a>
                    </div>
                  </div>!-->
            </div>
        </div>
    </div>
</div>

