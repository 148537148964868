import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { Florist } from './classes/florist';
import { map } from 'rxjs/operators';
import { Towns } from './classes/towns';


@Injectable({
  providedIn: 'root'
})
export class FindafloristapiService {

  private showstatus= new Subject<any>();
  private floristrebreadcumresults= new Subject<any>();
  private townval1= new Subject<any>();
  private townval= new Subject<any>();
  shoplook$ = this.showstatus.asObservable();
  private floristresults =new BehaviorSubject('');
  //currentSearchkey= this.showstatus.asObservable();
  currentfloristrebreadcum$= this.floristrebreadcumresults.asObservable();
  currenttownval1$ = this.townval1.asObservable();
  currenttownval$ = this.townval.asObservable();
  public getResults$(){
     return this.floristresults.asObservable()
  }

 

  constructor(private httpclient: HttpClient ) { }

  changeSearchK(data:number){
    this.showstatus.next(data)
  }
  getBrdcum(data:any){
    this.floristrebreadcumresults.next(data)
  }
  getshopval1(data:any){
    this.townval1.next(data)
  }
  getshopval(data:any){
    this.townval.next(data)
  }

  

  
  
  floristrecords(oflorist:Florist): Observable<any> {

    const floristheaders= new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic cGV0YWxzZGlyOnAzdGFscyMj'  
    });
      return this.httpclient.post("https://www.locateaflorist.com.au/FindAFlorist/api/Florist/GetFlorPops", oflorist, { headers: floristheaders} )
  }
  
  avilabletowns(otowns:Towns): Observable<any> {

    const floristheaders= new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic cGV0YWxzZGlyOnAzdGFscyMj'  
    });
    return this.httpclient.post("https://www.locateaflorist.com.au/FindAFlorist/api/Florist/GetTownList", otowns, { headers: floristheaders} )
  }

  featureflorist(oflorist1:Florist): Observable<any> {

    const floristheaders= new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic cGV0YWxzZGlyOnAzdGFscyMj'  
    });
    return this.httpclient.post("https://www.locateaflorist.com.au/FindAFlorist/api/Florist/GetFeaturedFlorists", oflorist1, { headers: floristheaders} )
  }

  
  
}
