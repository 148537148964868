<app-breadcrumb [style.display]="'none'"></app-breadcrumb>
<div class="row searchwrap" ui-view="app-searchresults">
      
    <div class="container floristdetailsc">    
        <div class="row townval1" >
            <p>Choose one of these {{townval1 | titlecase}} Florists and have a beautiful, hand arranged flower bouquet delivered today. Same Day Flower Delivery available from most shops until 2:00 P.M. in the recipient's time zone.</p>
        </div>
        <div class="row townval">
            <h1>{{townval | titlecase}}</h1>
        </div>    
        <div class="row floristdetails" *ngFor="let florist of floristsinfo" [ngClass]="{'clssun': florist.Days.substr(6, 6) != 'O'}" [class.show]="showstatus==1" [class.hide]="showstatus==0">
            
                <div class="col-12 col-md-8 col-lg-8 floristdetails1" [ngClass]="readmore ? 'showContent' : 'hideContent'" #floristdetails1>
                <div class="detailswrap col-8">
                <h2 class="shopname">{{florist.Organ}} </h2>
                <h3 class="shopaddress">{{florist.Address1}} {{florist.Address2}},{{florist.Town}},{{florist.State}},{{florist.Postcode}}</h3>
                <p>Open: M-F 9-5p  SAT 9-12p</p>
                <p *ngIf="florist.Closed !='1/1/1900' && florist.ReOpen > (myDate | date: 'shortDate')">Closed untill:{{florist.ReOpen | date :'dd/MM/yyyy' }}</p>
                <p *ngIf="(florist.Closed !='' && florist.Closed !='1/1/1900')&& florist.ReOpen=='' ">Currently Closed</p>
                
                <div class="fafcontact">
                <div class="fafwebsitbtn" *ngIf=florist.URL><a href={{florist.URL}} target="_blank"><span class="vistweb">Visit Website</span></a></div>              
            
                    <span  *ngIf=florist.BPhone><b>Phone:</b> {{florist.BPhone}}</span>
                    </div>
                </div>
                <div class="col-4"><a href="http://maps.google.com/maps?q={{florist.Address1.replace(' ', '+')}}+{{florist.Address2.replace(' ', '+')}}+{{florist.Town.replace(' ', '+')}}+{{florist.State.replace(' ', '+')}}+{{florist.Postcode.replace(' ', '+')}}" target="_blank"><img src="http://maps.google.com/maps/api/staticmap?size=200x200&amp;markers=size:mid|color:yellow|label:|{{florist.Address1.replace(' ', '+')}}+{{florist.Address2.replace(' ', '+')}}+{{florist.Town.replace(' ', '+')}}+{{florist.State.replace(' ', '+')}}+{{florist.Postcode.replace(' ', '+')}}&amp;sensor=false&amp;key=AIzaSyCk0YSf_h-hTSx2iqfZ8LVO63apmJ40ECU" alt="Flower delivery to {{florist.Organ}},{{florist.State}}"></a></div>

                </div>
                <div class="col-12 col-md-4 col-lg-4" >
                    <div class="dtlswrap1" >
                        <div class="col-12">
                            <i class="fa fa-truck"></i><span>   Same Day Delivery</span><br/>
                            <div *ngIf="florist.Days.substr(6, 6) == 'O'">
                            <i class="fa fa-calendar" aria-hidden="true" ></i><span>   Open Sundays</span></div>
                            
                    </div>
                    <div class="col-12 prdkyavlb" >
                        <div class="row">
                                <div class="col-6 prddetailsdiv" *ngIf=florist.Functions>
                                    <p class="circle">F</p>
                                    <p class="prdtkey">Functions/<br/>Events</p>
                                </div>
                                <div class="col-6 prddetailsdiv" *ngIf="florist.Hampers">
                                    <p class="circle">H</p>
                                    <p class="prdtkey">Hampers</p>
                                </div>
                                <div class="col-6 prddetailsdiv" *ngIf="florist.Weddings">
                                    <p class="circle">W</p>
                                    <p class="prdtkey">Weddings</p>
                                </div>
                                <div class="col-6 prddetailsdiv" *ngIf="florist.Toys">
                                    <p class="circle">T</p>
                                    <p class="prdtkey">Toys</p>
                                </div>
                                <div class="col-6 prddetailsdiv" *ngIf="florist.Plants">
                                    <p class="circle">P</p>
                                    <p class="prdtkey">Plants</p>
                                </div>
                                <div class="col-6 prddetailsdiv"  *ngIf="florist.Chocolates">
                                    <p class="circle">C</p>
                                    <p class="prdtkey">Chocs</p>
                                </div>
                                <div class="col-6 prddetailsdiv"  *ngIf="florist.Alcohol">
                                    <p class="circle">A</p>
                                    <p class="prdtkey">Alcohol</p>
                                </div>
                                <div class="col-6 prddetailsdiv"  *ngIf="florist.Balloons">
                                    <p class="circle">B</p>
                                    <p class="prdtkey">Balloons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
        </div>
        
     </div>
</div>






