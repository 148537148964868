<app-breadcrumb></app-breadcrumb>
<div class="row bdywrap">
    <div class="container">
        <div class="row dsplyctyhd">
            <span class="ttlh1">Locate a Florist Sitemap</span>  <br/><br/>
            <span class="ttlh">Find Local Florists in Top Cities</span> <br/>
        </div>
        <div class="row dsplytopcity">         
            <div class="col-12 col-md-3"  (click)="topcity($event)" *ngFor="let city of Topcity">
                <a id={{city.town}} class="faftoptown" [routerLink]="">{{city.name}}</a>
            </div>
            
        </div>
        <div class="row">
            <div class="dsplystates">
                <span class="ttlh">Find Local Florists by State or Province</span><br/><br/>
                <div class="col-12 col-3" (click)="citylist($event)" *ngFor="let state of States">
                <a id={{state.abbreviation}} rel={{state.abbreviation1}} class="faftown" [routerLink]="" >{{state.name}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

