import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver, ChangeDetectorRef, ViewContainerRef,OnChanges,ElementRef,Injectable,Renderer2 } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Florist} from '../classes/florist'
import { BehaviorSubject, pipe  } from 'rxjs';
import { FindafloristapiService } from '../findafloristapi.service';
import { map } from 'rxjs/operators';
import { Router,ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery'


@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.css'],
})

@Injectable()
export class SearchresultsComponent implements OnInit, OnChanges{

  title;
  //floristsd:Florist[]=floristsData;
  shoplook:string;
  url:string;
  myVar = "";
  fnfkey='';
  myDate=new Date();
  isShow = true;
  showstatus:number=1
  readmore: boolean = false;
  townval1:any;
  townval:any;

  
 @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
 @Input() @Input()   floristsinfo:Florist

  constructor(private _findafloristapiservice:FindafloristapiService, private http:HttpClient, private route: Router, private router:ActivatedRoute,private cfr: ComponentFactoryResolver,private elementRef:ElementRef,private renderer: Renderer2,private titleService: Title,private meta: Meta ){ 
  this._findafloristapiservice.shoplook$.subscribe(data=>{
    this.showstatus=data;
  });
  }  
 

  ngOnChanges() {  
      
    
  }
  readmoreEvent(){
    this.readmore = !this.readmore;       
}
  opensunday1(){
  
  }


  ngOnInit() {
    $(document).ready(function () {
      setTimeout(function () {  
        $('.townval1').eq(1).remove();
        $('.townval').eq(1).remove();
         }, 600);

    });
    this.router.queryParams.subscribe(params => {      
    });
    let floristsessionval=sessionStorage.getItem('floristval');
    let floristsrctypsessionval=sessionStorage.getItem('srchtyp');
    let searchtxt =this.router.snapshot.queryParamMap.get('d');
    if(floristsessionval !=searchtxt){
      sessionStorage.setItem('floristval',searchtxt);
      sessionStorage.setItem('srchtyp',this.router.snapshot.queryParamMap.get('type'));

    }
    var srchtyp =this.router.snapshot.queryParamMap.get('mode');

    if(srchtyp== 'sugg'){
      
     
      let nebcrumlink=this.route.url.split('-florist');
      let nebcrumlink1=nebcrumlink[1].split('-flower-delivery/');
      let nebcrumlink2=nebcrumlink1[1].replace(/\//g, "-");
      let CityName = nebcrumlink1[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
      let nebcrumlink3=nebcrumlink2.split('?');
      let nebcrumlink4=nebcrumlink[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
      this.title="Local Flower Delivery in"+ CityName +"," + nebcrumlink4 +"- Locate a Florist"
      this.titleService.setTitle(this.title);
      this.meta.addTag({name: 'description', content: 'Needing to send flowers from a local'+ CityName +','+ nebcrumlink4+' florist?  Need flowers for any occasion or event happening in '+ CityName +','+ nebcrumlink4+'?  Choose one of these local florists for the best hand arranged and artfully designed floral arrangements available!'});
      this.townval1=CityName;
      this.townval= "Florist Near"+ CityName+','+nebcrumlink4;
      let nebcrumlink5=nebcrumlink3[0].replace(/-/g, " ").toUpperCase();
      

    

    }else if(srchtyp== 'srch'){
      let nebcrumlink6=this.route.url.split('-floristdelivery');
      this.townval1=nebcrumlink6[0].replace(/\//g, " ");
      this.townval= "Florist results related your search"+ nebcrumlink6[0].replace(/\//g, " ");
      
      
    }
    this._findafloristapiservice.currenttownval1$.subscribe(data=>{
      this.townval1=data;      
    });
    this._findafloristapiservice.currenttownval$.subscribe(data=>{
      this.townval=data;      
    });
    
  }
  
  

}
