import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Towns } from 'src/app/classes/towns';
import { HttpClient } from "@angular/common/http";
import { Session } from 'protractor';
import { FindafloristapiService } from 'src/app/findafloristapi.service';
import { Florist } from 'src/app/classes/florist';

@Component({
  selector: 'app-townslist',
  templateUrl: './townslist.component.html',
  styleUrls: ['./townslist.component.css']
})
export class TownslistComponent implements OnInit {

  towns:Towns
  floristsinfo: Florist

  constructor(private _findafloristapiservice:FindafloristapiService,private http:HttpClient,private router: Router,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {      
    });
    let townvalue=sessionStorage.getItem('townvalue');
    let townsessnval=this.route.snapshot.queryParamMap.get('state');
    if(townvalue != townsessnval){
      var otowns = new Towns();
      otowns.State = townsessnval
      otowns.Country= "AU";
      this._findafloristapiservice.avilabletowns(otowns).subscribe(data =>{ this.towns= data;});
      sessionStorage.setItem('townvalue',this.route.snapshot.queryParamMap.get('state'));
    }else{
    var otowns = new Towns();
    otowns.State = townvalue
    otowns.Country= "AU";
    this._findafloristapiservice.avilabletowns(otowns).subscribe(data =>{ this.towns= data;});
    sessionStorage.setItem('townvalue',this.route.snapshot.queryParamMap.get('state'));
    }
  }

  faftownflorist(event){
    var oflorist = new Florist();
    oflorist.SearchText = event.srcElement.id;
    oflorist.LiveOnly= true;
    oflorist.SearchType="CSZ"
    this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ if (Object.keys(data).length === 0) {
      this.router.navigateByUrl('/search')
    }else{
      this.floristsinfo= data;
    }});     
    sessionStorage.setItem('floristval',event.srcElement.id);
    sessionStorage.setItem('srchtyp',"CSZ");
    let townroutelink =event.srcElement.id;
    this.router.navigateByUrl('/flowerdelivery/'+ townroutelink +'?type=CSZ&CSZ='+townroutelink);
  

  }

}
