<app-breadcrumb></app-breadcrumb>
<div class="row bdywrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
            <h2>About Locate a Florist Powered by Petals Network</h2>
            <p>Petals' locateaflorist.com.au makes it easy to locate a flower shop, anywhere in Australia and New Zealand. With more than 30 years of experience connecting customers to caring florists, trust Petals Network to show you the right shop in the right neighborhood. Our network of "over" 20,000 caring florists is made up of professionals who have met and exceeded Petals’ quality and reliability standards. Our partners cover the entire nation, so wherever your loved one happens to be Petals can help you Locate a Florist.
            </p>
            <p>By shopping with a local florist you "not" only assure that your arrangements will be made with the freshest flowers in the area, but you also guarantee that your order will be created by a skilled professional giving your gift the personal attention it deserves. Plus, shopping locally lets you have your gift delivered on the same day you place your order "in most areas"..
            </p>
            <p>With Petals locateaflorist.com.au you can find a local florist and link directly to their website. Please note that Petals.com.au coupons and discounts may not apply on individual florists' websites.
            </p>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-8">
            <h2>Contact Locate a Florist </h2>
            <p>If you have any thoughts about your florist experience on Locate a Florist you'd like to share, we would love to hear from you. Please email Locate a Florist at hq@petalsnetwork.com. We're always working to improve our service. </p>
            <p>If you're interested in advertising with Locate a Florist, or if you're a professional looking to become a member florist and have your shop listed on this website, please contact Locate a Florist at membership@petalsnetwork.com.</p>
            </div>
            <div class="col-12 col-md-4">
                <img class="img-fluid" src="/assets/images/aboutus1.jpg">
            </div>
            </div>
    </div>
</div>
