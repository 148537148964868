import { Component, OnInit } from '@angular/core';
import { Florist } from '../classes/florist';
import { Router, ActivatedRoute } from '@angular/router';
import { FindafloristapiService } from '../findafloristapi.service';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-townflorists',
  templateUrl: './townflorists.component.html',
  styleUrls: ['./townflorists.component.css']
})
export class TownfloristsComponent implements OnInit {
  florists:Florist
  showstatus:number=1
  myDate=new Date();
  isReadMore = true
  town: string;

  constructor(private _findafloristapiservice:FindafloristapiService, private http:HttpClient, private router:ActivatedRoute,private route: Router) { 
    this._findafloristapiservice.shoplook$.subscribe(data=>{
      this.showstatus=data;
      console.log(data);
    });
  }  
  showText() {
    this.isReadMore = !this.isReadMore
 }
  ngOnInit(): void {

    this.router.queryParams.subscribe(params => {      
    });
    let floristsessionval=sessionStorage.getItem('floristval');
    let floristsrctypsessionval=sessionStorage.getItem('srchtyp');
    let searchtxt =this.router.snapshot.queryParamMap.get('CSZ')||this.router.snapshot.queryParamMap.get('name');
    if(floristsessionval !=searchtxt){
      var oflorist = new Florist();    
      oflorist.SearchText = searchtxt;
      oflorist.LiveOnly= true;
      oflorist.SearchType=this.router.snapshot.queryParamMap.get('type')
      this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{if (Object.keys(data).length === 0) {
        this.route.navigateByUrl('/search')
      }else{
        this.florists= data;
      }});     
      sessionStorage.setItem('floristval',searchtxt);
      sessionStorage.setItem('srchtyp',this.router.snapshot.queryParamMap.get('type'));

    }else{
      var oflorist = new Florist();    
      oflorist.SearchText = floristsessionval;
      oflorist.LiveOnly= true;
      oflorist.SearchType=floristsrctypsessionval
      this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ if (Object.keys(data).length === 0) {
        this.route.navigateByUrl('/search')
      }else{
        this.florists= data;
        this.town= searchtxt;
        console.log(this.town);
      }});     
      sessionStorage.setItem('floristval',searchtxt);
      sessionStorage.setItem('srchtyp',this.router.snapshot.queryParamMap.get('type'));
    }
  }

}
