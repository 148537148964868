import { Component, ElementRef, Injectable, ViewChild,Pipe,PipeTransform } from '@angular/core';
import {BrowserModule, Title ,Meta} from '@angular/platform-browser';
import { FindafloristapiService } from './findafloristapi.service';
import { Florist } from './classes/florist';
import { Router, ActivatedRoute } from '@angular/router';
import { Location,DatePipe } from '@angular/common';
import { Days } from './classes/days';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { catchError,debounceTime  } from 'rxjs/operators';
import { observable, of } from 'rxjs';
import { flatten } from '@angular/compiler';
import StatesJson from './sitemap/states.json'
interface STATES {
  name: String;
  abbreviation: String;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

//@Injectable()
export class AppComponent {
  public sessionStorage = sessionStorage;
  title = 'petals-directory';
  searchForm:FormGroup;
  shoplook:string;
  florists:Florist;
  searchKey:string;
  searchtype:string
  isShow = false;
  ishide= true;
  newPlaceHolder: string = "Enter city, state, or post code";
  newname: string ="CSZ";
  featureflorists:Florist;
  myDate=new Date();
  suggestedflorist:Florist;
  public errorApi = false;
  srchisShown: boolean = false ;
  hassrchtxt:boolean=false;
  floristbreadcumb : any ="florists";
  States: STATES[] = StatesJson;
  townval1:any;
  townval:any;

  @ViewChild('searchfld')searchfld: ElementRef
  @ViewChild('sugfindflrst1')sugfindflrst1: ElementRef


  constructor(private _findafloristapiservice:FindafloristapiService, private route: Router,private router:ActivatedRoute, private _location: Location, private formBuilder: FormBuilder,private titleService: Title,private meta: Meta  ){
    this.searchForm = this.formBuilder.group({
      searchtxt: ''
    });
   }

  ngOnInit(){  
    setTimeout (() => {
    let floristsessionval=sessionStorage.getItem('floristval');
    let floristsrctypsessionval=sessionStorage.getItem('srchtyp');
    let floristsrhmnosessionval=sessionStorage.getItem('srcmnoval');
    var oflorist = new Florist();    
    oflorist.SearchText = floristsessionval;
    oflorist.LiveOnly= true;
    oflorist.MemberNumber = floristsrhmnosessionval;
    oflorist.SearchType=floristsrctypsessionval
    this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ this.florists= data;}); 
  }, 100);
  var oflorist1 = new Florist();    
  this._findafloristapiservice.featureflorist(oflorist1).subscribe(data =>{ this.featureflorists= data;}); 
  this.title="Locate a Local Florist for Fresh Flower Delivery | Locate a Florist"
      this.titleService.setTitle(this.title);
      //this.meta.addTag({name: 'keywords', content: 'Needing to send flowers from a local #CityName, #State florist?  Need flowers for any occasion or event happening in #CityName, #State?  Choose one of these local florists for the best hand arranged and artfully designed floral arrangements available!'});
      this.meta.addTag({name: 'description', content: 'Order flowers online from a florist in your delivery area.  Real flower shops, hand designed floral arrangements.  Get fresh flowers unique gifts hand delivered right to your door.'});
  
      var srchtyp =this.router.snapshot.queryParamMap.get('mode');

      if(srchtyp== 'sugg'){
        
       
        let nebcrumlink=this.route.url.split('-florist');
        let nebcrumlink1=nebcrumlink[1].split('-flower-delivery/');
        let nebcrumlink2=nebcrumlink1[1].replace(/\//g, "-");
        let CityName = nebcrumlink1[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
        let nebcrumlink3=nebcrumlink2.split('?');
        let nebcrumlink4=nebcrumlink[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
        this.title="Local Flower Delivery in"+ CityName +"," + nebcrumlink4 +"- Locate a Florist"
        this.titleService.setTitle(this.title);
        this.meta.addTag({name: 'description', content: 'Needing to send flowers from a local'+ CityName +','+ nebcrumlink4+' florist?  Need flowers for any occasion or event happening in '+ CityName +','+ nebcrumlink4+'?  Choose one of these local florists for the best hand arranged and artfully designed floral arrangements available!'});
        this.townval1=CityName;
        this.townval= "Florist Near"+ CityName+','+nebcrumlink4;
        let nebcrumlink5=nebcrumlink3[0].replace(/-/g, " ").toUpperCase();
  
      
  
      }else if(srchtyp== 'srch'){
        setTimeout(()=>{       
        let nebcrumlink6=this.route.url.split('-floristdelivery');
        this.townval1=nebcrumlink6[0].replace(/\//g, " ");
        this.townval= "Florist results related your search"+ nebcrumlink6[0].replace(/\//g, " ");
      }, 1000);
        
      }
   
    }

  findbname(){
    this.ishide= !this.ishide;
    this.isShow = !this.isShow;
    this.newPlaceHolder="Enter Florist shop name";
    this.newname="name";
  }

  findbcsz(){
    this.ishide= !this.ishide;
    this.isShow = !this.isShow;
    this.newPlaceHolder = "Enter city, state, or post code";
    this.newname ="CSZ";
  }
 
 

  findflrst(){
    var oflorist = new Florist();
    oflorist.SearchText = this.searchfld.nativeElement.value;
    oflorist.LiveOnly= true;
    oflorist.SearchType=this.searchfld.nativeElement.getAttribute('name')
    this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ if (Object.keys(data).length === 0) {
      this.route.navigateByUrl('/search')
    }else{
      this.florists= data;
    }});     
    sessionStorage.setItem('floristval',this.searchfld.nativeElement.value);
    sessionStorage.setItem('srchtyp',this.searchfld.nativeElement.getAttribute('name'));
    let routlink2=this.searchfld.nativeElement.value
    setTimeout (() => {     
      let searchval = this.searchfld.nativeElement.value.toLowerCase()
      this.floristbreadcumb=searchval;
      console.log(this.floristbreadcumb);
      this._findafloristapiservice.getBrdcum(this.floristbreadcumb);
      this._findafloristapiservice.getshopval1(this.floristbreadcumb);
    this.townval= "Florist results related your search "+ this.floristbreadcumb;
    this._findafloristapiservice.getshopval(this.townval);
      if(searchval == "nsw"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/new-south-wales?state=NSW');
      }else if(searchval== "vic"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/victoria?state=VIC');
      }else if(searchval === "qld"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/queensland?state=QLD');
      }else if(searchval === "tas"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/tasmania?state=TAS');
      }else if(searchval === "sa"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/south-australia?state=SA');
      }else if(searchval == "wa"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/western-australia?state=WA');
      }else if(searchval === "nt"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/northern-territory?state=NT');
      }else if(searchval === "act"){
        this.searchfld.nativeElement.value='';
        this.route.navigateByUrl('/townslist/australian-capital-territory?state=ACT');
      }else{
      this.searchfld.nativeElement.value='';
      this.route.navigateByUrl('/'+ routlink2 + '-floristdelivery?mode=srch&type='+this.searchfld.nativeElement.getAttribute('name')+"&d="+sessionStorage.getItem('floristval'));
      }
      
    }, 100); 
  this.srchisShown = false;
   
  }

 
  hasRoute(route: string) {
    return this.route.url.includes(route);
  }

  getUserIdsFirstWay($event) {
    let srchqry = this.searchfld.nativeElement.value
    let srchattr=this.searchfld.nativeElement.getAttribute('name')
    //if(srchattr==="name"){
    let srchqrylength = this.searchfld.nativeElement.value.length
    console.log(this.searchfld.nativeElement.value.length +"search value"+ this.searchfld.nativeElement.value.length);
    if(srchqrylength >2){
      console.log("search length >2");
    this.hassrchtxt=true;
    var oflorist = new Florist();
    oflorist.SearchText = srchqry.trim();
    oflorist.LiveOnly= true;
    oflorist.SearchType=this.searchfld.nativeElement.getAttribute('name')
  
    this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ this.suggestedflorist= data;}); 
    console.log(this.suggestedflorist);       
    this.srchisShown = ! this.srchisShown;   
    }else{
      console.log("inside hide sugg");
      this.srchisShown =false;
    }
  }
  
  tooglesugge(){
    let srchqry1 = this.searchfld.nativeElement.value.length
    if(srchqry1 > 2){

      this.srchisShown = ! this.srchisShown;
    }
    

  }

 

  sugfindflrst(event){
    setTimeout (() => {
     var target = event.target || event.srcElement || event.currentTarget;
    let routlinkattr= target.attributes.rel
    var routlink1 = routlinkattr.nodeValue.replace(/ /g, "-");
    var oflorist = new Florist();
    oflorist.SearchText =event.srcElement.id ||sessionStorage.getItem('floristval');
    oflorist.LiveOnly= true;
    oflorist.MemberNumber=event.srcElement.title;
    oflorist.SearchType="name";
    this._findafloristapiservice.floristrecords(oflorist).subscribe(data =>{ if (Object.keys(data).length === 0) {
      this.route.navigateByUrl('/search')
    }else{
      this.florists= data;
    }});    
    let nebcrumlink=routlink1.split('-florist');
    let nebcrumlink1=nebcrumlink[1].split('-flower-delivery/');
    let nebcrumlink2=nebcrumlink1[0];
    let nebcrumlink3=nebcrumlink1[1].replace(/\//g, "-");
    let newbrdstate
    if(nebcrumlink[0] == "nsw"){
      newbrdstate="new-south-wales"
    }else if(nebcrumlink[0]== "vic"){
      newbrdstate="victoria"
    }else if(nebcrumlink[0] === "qld"){
      newbrdstate="queensland"
    }else if(nebcrumlink[0] === "tas"){
      newbrdstate="tasmania"
    }else if(nebcrumlink[0] === "sa"){
      newbrdstate="south-australia"
    }else if(nebcrumlink[0] == "wa"){
      newbrdstate="western-australia"
    }else if(nebcrumlink[0] === "nt"){
      newbrdstate="northern-territory"
    }else if(nebcrumlink[0] === "act"){
      newbrdstate="australian-capital-territory"
    }
    console.log(newbrdstate+'-florist/'+nebcrumlink2+'-flower-delivery/'+nebcrumlink3);
    let nebcrumlink4=newbrdstate.replace(/-/g, " ").toUpperCase();
    let nebcrumlink5=nebcrumlink3.replace(/-/g, " ").toUpperCase();
    console.log(nebcrumlink4);
    var routlink=newbrdstate+'-florist'+nebcrumlink2+'-flower-delivery/'+nebcrumlink3
    this.searchfld.nativeElement.value='';
    this.floristbreadcumb=nebcrumlink4+" > "+nebcrumlink5;
    this._findafloristapiservice.getBrdcum(this.floristbreadcumb);

    this._findafloristapiservice.getshopval1(nebcrumlink2.replace(/\//g, " "));
    this.townval= "Florist Near"+ nebcrumlink2.replace(/\//g, " ");+','+nebcrumlink4;
    this._findafloristapiservice.getshopval(this.townval);
    console.log(this.floristbreadcumb);
    this.srchisShown = ! this.srchisShown;
      this.route.navigateByUrl('/'+ routlink+'?mode=sugg&type='+this.searchfld.nativeElement.getAttribute('name')+"&d="+sessionStorage.getItem('floristval'));
    //}
  }, 200); 

  }

  
  
}
