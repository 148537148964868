
<header>
  <div class="header-wrap">

    <div class="row hdrwrap">
      <div class="container">
        <div class="row logowrap">
          <a href="/">
            <img class="img-fluid logoimg" src="/assets/images/faf_logo.png">
          </a>
        </div>

        <div class="row hdrheading">
          <h2>
            Fresh Flower Delivery From Your Local Florist
          </h2>
        </div>
        <form [formGroup]="searchForm">
          <div class="row searchform">
            
            <input class="searchfld m-navbar-search" id="searchfld" [name]="newname" #searchfld (click)="tooglesugge()" type="text" [placeholder]="newPlaceHolder" (input)="getUserIdsFirstWay($event)" autocomplete="off" formControlName="searchtxt">
           
            <button class="searchbtn m-navbar-search-btn" id="searchbtn"  value="Find a Florist" type="submit" (click)="findflrst()">Locate a Florist</button><br/>          
        
            <a class="srchbyflwr" (click)="findbname()" [hidden] = "isShow" [routerLink]="">Search by flower shop name</a>
            <a class="srchbyflwr" (click)="findbcsz()" [hidden] = "ishide" [routerLink]="">Search by City/State/Zipcode</a>
          
          </div>
        </form>
          <!--<section  class="suggestions" *ngIf="isShown" [style.width.px]="searchfld.offsetWidth">
        
          <ng-template ngFor let-florist [ngForof]="suggestedflorist" let-i="index">
          <div *ngIf="i < 2">
            <a>{{florist.Organ}} - {{florist.Postcode}}</a>
          </div>
          </ng-template>
        </section>!-->
      <div class="suggestions" *ngIf="srchisShown" [style.width.px]="searchfld.offsetWidth" >
          <div class="sugre"   *ngFor="let florist of suggestedflorist ; let i=index">
          <a [routerLink]=""  (click)="sugfindflrst($event)" rel="{{florist.State| lowercase}}-florist/{{florist.Town.replace(' ', '-')| lowercase}}-flower-delivery/{{florist.Organ.replace(' ', '-')| lowercase}}" class="suggsflrst" title={{florist.MemberNumber}}  #sugfindflrst1 *ngIf="i < 10" id={{florist.Organ}}>{{florist.Organ}} - {{florist.Postcode}}</a>
         </div>
      </div> 
        </div>
      </div>
     

  </div>
  <app-breadcrumb *ngIf="hasRoute('florist')"></app-breadcrumb>
</header>


<div class="row  bdywrap homecntnt" >
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-8">
          <h2>Fresh flowers. Local florists. Same day delivery.</h2>
          <p>Whether you need to say Congratulations, Happy Birthday, or just want to send a smile, sending flowers for friends and family is easy and fun with Locate a Florist, the best online florist directory available.</p>
          <p>You are a quick search away from finding a local florist that can deliver beautiful handcrafted arrangements that are sure to delight. Order now to send the summer season’s best blooms, including roses, carnations, gerbera, lilies and more!</p>
          <p>Last minute flower deliveries are not a problem here! All florists that are affiliated with <a>Locate a Florist</a> provide reliable delivery as well as same day service throughout the Australia and New Zealand.</p>
          <p>Find a local florist now to stay connected and show you care when you can't be there.</p>
          </div>
          <div class="col-12 col-md-4">
              <img class="img-fluid" src="/assets/images/petals-541x600.jpg">
          </div>
      </div>  
      <div class="row">
        <h2>Our Featured Florists</h2>
      </div>
      <div class="row" *ngFor="let featureflorist of featureflorists">
        <div class="col-12  fetaurefloristdetails1" >
          <div class="fetaurefloristdetailswrap col-8">
                <h2 class="shopname">{{featureflorist.Organ}} </h2>
                <p>{{featureflorist.Address1}} {{featureflorist.Address2}},{{featureflorist.Town}},{{featureflorist.State}},{{featureflorist.Postcode}}</p>
                <p>Open: M-F 9-5p  SAT 9-12p</p>
                <p *ngIf="featureflorist.Closed !='1/1/1900' && featureflorist.ReOpen > (myDate | date: 'shortDate')">Closed untill:{{featureflorist.ReOpen | date :'dd/MM/yyyy' }}</p>
                <p *ngIf="(featureflorist.Closed !='' && featureflorist.Closed !='1/1/1900')&& featureflorist.ReOpen=='' ">Currently Closed</p>
                <div class="fafcontact">
                  <div class="fafwebsitbtn" *ngIf=featureflorist.URL><a href={{featureflorist.URL}} target="_blank"><span class="vistweb">Visit Website</span></a></div>              
              
                      <span  *ngIf=featureflorist.BPhone><b>Phone:</b> {{featureflorist.BPhone}}</span>
                </div>
            </div>
            <div class="col-4"><a href="http://maps.google.com/maps?q={{featureflorist.Organ}}+{{featureflorist.Address1}}+{{featureflorist.Address2}}+{{featureflorist.Town}}+{{featureflorist.State}}+{{featureflorist.Postcode}}" target="_blank"><img src="http://maps.google.com/maps/api/staticmap?size=200x200&amp;markers=size:mid|color:yellow|label:|{{featureflorist.Organ}}+{{featureflorist.Address1}}+{{featureflorist.Address2}}+{{featureflorist.Town}}+{{featureflorist.State}}+{{featureflorist.Postcode}}&amp;sensor=false&amp;key=AIzaSyCk0YSf_h-hTSx2iqfZ8LVO63apmJ40ECU" alt="Flower delivery to {{featureflorist.Organ}} {{featureflorist.Town}} {{featureflorist.State}}"></a></div>

        </div>
      </div> 
  </div>
</div>

<div class="row  bdywrap homecntnt1" *ngIf="hasRoute('florist')">
  <div class="container">    
   <!-- <div class="row" >
      <p>Choose one of these {{townval1}} Florists and have a beautiful, hand arranged flower bouquet delivered today. Same Day Flower Delivery available from most shops until 2:00 P.M. in the recipient's time zone.</p>
  </div>
  <div class="row">
      <h1>Florist Near {{townval}}</h1>
  </div>  --> 
    <app-searchresults *ngIf="hasRoute('florist')"  [floristsinfo]="florists"></app-searchresults> 
    <div class="row" *ngIf="!florists">
      <div class="col-12" >
        <p class="noresults">There are no Florist for selection</p>
      </div>
    </div>
    
 
           
  
    </div>
  </div>
<router-outlet></router-outlet>
  <!-- Footer -->
  <footer>
    <div class="row">
      <div class="col-12 footerlinks">
        <a href="aboutus">ABOUT US</a><span>|</span>
        <a href="sitemap">SITEMAP</a><span>|</span>
        <a href="privacypolicy">PRIVACY POLICY</a>
      </div>    
    </div>
    <div class="row footerimgwrap">
      <img class="img-fluid footerimg" src="/assets/images/faf_footer.png">
    </div>
  </footer>



