import { Component, OnInit,ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '../breadcrumb.model';
import { BreadcrumbService } from '../breadcrumb.service';
import { style } from '@angular/animations';
import { FindafloristapiService } from '../findafloristapi.service';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]>;
  //myDiv;
  showstatus:number=1
  floristrebreadcumresults:any;
  townval1:any;
  townval:any;

  constructor(private readonly breadcrumbService: BreadcrumbService,private _findafloristapiservice:FindafloristapiService, private route: Router,private router:ActivatedRoute, private elementRef:ElementRef) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$; 
    /*this._findafloristapiservice.currentfloristrebreadcum$.subscribe(data=>{
      this.floristrebreadcumresults=data;
      console.log(data, "breadcumservice");
     
      
    });*/
   }

  ngOnInit(): void {
    
    var srchtyp =this.router.snapshot.queryParamMap.get('mode')
    if(srchtyp== 'sugg'){
      let routlink1 = this.route.url;
      console.log(routlink1);
      let nebcrumlink=this.route.url.split('-florist');
      let nebcrumlink1=nebcrumlink[1].split('-flower-delivery/');
      let nebcrumlink2=nebcrumlink1[1].replace(/\//g, "-");
      console.log(nebcrumlink1[1]);
      console.log(nebcrumlink[0])
      let nebcrumlink3=nebcrumlink2.split('?');
      let nebcrumlink4=nebcrumlink[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
    let nebcrumlink5=nebcrumlink3[0].replace(/-/g, " ").toUpperCase();
    this.floristrebreadcumresults=nebcrumlink4+" > "+nebcrumlink5;
    let CityName = nebcrumlink1[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
    this.townval1=CityName;
      this.townval= "Florist Near"+ CityName+','+nebcrumlink4;

    }else if(srchtyp== 'srch'){
      let nebcrumlink6=this.route.url.split('-floristdelivery');
      this.floristrebreadcumresults=nebcrumlink6[0].replace(/\//g, "-").replace(/-/g, " ").toUpperCase();
      this.townval1=nebcrumlink6[0].replace(/\//g, " ");
      this.townval= "Florist results related your search "+ nebcrumlink6[0].replace(/\//g, " ");
    }
    this._findafloristapiservice.currentfloristrebreadcum$.subscribe(data=>{
      this.floristrebreadcumresults=data;      
    });
    this._findafloristapiservice.currenttownval1$.subscribe(data=>{
      this.townval1=data;  
      //console.log(this.townval1, "here in town 1");    
    });
    this._findafloristapiservice.currenttownval$.subscribe(data=>{
      this.townval=data;      
    });
    var srchtyp =this.router.snapshot.queryParamMap.get('state')
    if(srchtyp== 'NSW'){
      this.floristrebreadcumresults= "New South Wales"
    }else if(srchtyp== 'VIC'){
      this.floristrebreadcumresults= "victoria"
    }else if(srchtyp== 'QLD'){
      this.floristrebreadcumresults= "queensland"
    }else if(srchtyp== 'TAS'){
      this.floristrebreadcumresults= "tasmania"
    }else if(srchtyp== 'SA'){
      this.floristrebreadcumresults= "south australia"
    }else if(srchtyp== 'WA'){
      this.floristrebreadcumresults= "western australia"
    }else if(srchtyp== 'NT'){
      this.floristrebreadcumresults= "northern territory"
    }else if(srchtyp== 'ACT'){
      this.floristrebreadcumresults= "australian capital territory"
    }
    
  }

  opensunday(){
    /*alert("sunday");
    this.myDiv = this.elementRef.nativeElement.querySelector('.clssun');
    this.myDiv.style.display='none';*/
    if (this.showstatus == 0){
      this.showstatus=1
    }else{
      this.showstatus=0
    }
    
    this._findafloristapiservice.changeSearchK(this.showstatus);
  }

  hasRoute(route: string) {
    return this.route.url.includes(route);
  }

}
