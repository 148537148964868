<app-breadcrumb></app-breadcrumb>
<div class="row bdywrap">
    <div class="container">      
        <div class="row displaytowns">           
            <div class="col-12 col-md-3 displaytown" (click)="faftownflorist($event)" *ngFor="let town of towns">
                <a id={{town.Town}} class="faftown" [routerLink]="">{{town.Town}}</a>
            </div>
        </div>
    </div>
</div>
