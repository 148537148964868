import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SearchresultsComponent } from './searchresults/searchresults.component';
import { FindafloristapiService } from './findafloristapi.service';
import { RouterModule, ROUTES } from '@angular/router';
import { TownslistComponent } from './sitemap/townslist/townslist.component';
import { TownfloristsComponent } from './townflorists/townflorists.component';
import { FormsModule,ReactiveFormsModule  }   from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { SanitizeUrlPipe } from './safe.pipe';






@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SitemapComponent,
    AboutusComponent,
    PrivacypolicyComponent,
    BreadcrumbComponent,
    SearchresultsComponent,
    TownslistComponent,
    TownfloristsComponent,
    SearchComponent,
    SanitizeUrlPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
    //RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})
  ],
  
  //exports:[RouterModule],
  providers: [Title,FindafloristapiService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
