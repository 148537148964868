export class Florist{
        Organ: string;
        MemberNumber: string;
        CodeNo: number;
        Address1: string;
        Address2: string;
        Town: string;
        Suburb: string;
        Postcode: string;
        State: string;
        Coutry: string;
        Days: string;
        Closed: string;
        ReOpen: string;
        URL: string;
        Website: string;
        BPhone: string;
        FPhone: string;
        Functions: Boolean;
        Weddings: Boolean;
        Plants: Boolean;
        Alcohol: Boolean;
        Hampers: Boolean;
        Toys: Boolean;
        Chocolates: Boolean;
        Balloons: Boolean;
        Dried: Boolean;
        FlorPopID: number;
        SearchText: string;
        LiveOnly: boolean;   
        SearchType:string;
        DeliveryTo:string;
        StartDate:Date;

}