<div class="row  bdywrap">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8">
            <h2>Fresh flowers. Local florists. Same day delivery.</h2>
            <p>Whether you need to say Congratulations, Happy Birthday, or just want to send a smile, sending flowers for friends and family is easy and fun with Find a Florist, the best online florist directory available.</p>
            <p>You are a quick search away from finding a local florist that can deliver beautiful handcrafted arrangements that are sure to delight. Order now to send the summer season’s best blooms, including sunflowers, carnations, asters, water lilies and more!</p>
            <p>Last minute flower deliveries are not a problem here! All florists that are affiliated with <a>Find a Florist</a> provide reliable delivery as well as same day service throughout the US or Canada.</p>
            <p>Find a local florist now to stay connected and show you care when you can't be here.</p>
            </div>
            <div class="col-12 col-md-4">
                <img class="img-fluid" src="/assets/images/petals-541x600.jpg">
            </div>
        </div>
    </div>
</div>
