import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { HomeComponent } from './home/home.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { SearchresultsComponent } from './searchresults/searchresults.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { TownslistComponent } from './sitemap/townslist/townslist.component';
import { TownfloristsComponent } from './townflorists/townflorists.component';
import {LocationStrategy,PathLocationStrategy } from '@angular/common'
import { SearchComponent } from './search/search.component';


const routes: Routes = [
  //{ path: 'home', component: HomeComponent },
 // { path: '', redirectTo: '/home', pathMatch: 'full' }, 
  { path: 'sitemap', component: SitemapComponent,data: { breadcrumb: 'sitemap' } },
  { path: 'aboutus', component: AboutusComponent,data: { breadcrumb: 'AboutUs' } },
  { path: 'privacypolicy', component: PrivacypolicyComponent,data: { breadcrumb: 'Privacy Policy' } },
  {path: 'search', component: SearchComponent,data: { breadcrumb: 'search'}},
  { path: ':state-florist/:town-flower-delivery/:organ', component: SearchresultsComponent,data: { breadcrumb: '' } },
  { path: ':state-floristdelivery', component: SearchresultsComponent,data: { breadcrumb: '' } },
  //{ path: '**', component: SearchresultsComponent },  
  { path: 'townslist/:list', component: TownslistComponent,data: { breadcrumb: '' } },
  { path: 'flowerdelivery/:townflorists', component: TownfloristsComponent,data: { breadcrumb: 'florists' }},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    // Below line is optional as default LocationStrategy is PathLocationStrategy
    {provide: LocationStrategy, useClass: PathLocationStrategy} 
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
